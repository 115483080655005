import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _34fbf0e3 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _f5fde97e = () => interopDefault(import('~/pages/-product-details.vue' /* webpackChunkName: "" */))
const _62442680 = () => interopDefault(import('~/pages/-voucher-details.vue' /* webpackChunkName: "" */))
const _26bf80e8 = () => interopDefault(import('~/pages/-product-preview.js' /* webpackChunkName: "" */))
const _4e9be474 = () => interopDefault(import('~/pages/-hotel-details.vue' /* webpackChunkName: "" */))
const _75d80d8e = () => interopDefault(import('~/pages/-checkout.vue' /* webpackChunkName: "" */))
const _59fb6594 = () => interopDefault(import('~/pages/-checkout-for-order.vue' /* webpackChunkName: "" */))
const _6d8fb560 = () => interopDefault(import('~/pages/-success.vue' /* webpackChunkName: "" */))
const _88cf7a06 = () => interopDefault(import('~/pages/-contact.vue' /* webpackChunkName: "" */))
const _13f940e0 = () => interopDefault(import('~/pages/-contact-nettovorteile.vue' /* webpackChunkName: "" */))
const _33e005ff = () => interopDefault(import('~/pages/-search-result.vue' /* webpackChunkName: "" */))
const _18e55984 = () => interopDefault(import('~/pages/-date-search.vue' /* webpackChunkName: "" */))
const _998b0a28 = () => interopDefault(import('~/pages/-map-search.vue' /* webpackChunkName: "" */))
const _f166661e = () => interopDefault(import('~/pages/-category.vue' /* webpackChunkName: "" */))
const _35988ca3 = () => interopDefault(import('~/pages/-category-preview.js' /* webpackChunkName: "" */))
const _4cf5c56a = () => interopDefault(import('~/pages/-top-hotels.vue' /* webpackChunkName: "" */))
const _611768f6 = () => interopDefault(import('~/pages/-cancellation.vue' /* webpackChunkName: "" */))
const _1d7e9874 = () => interopDefault(import('~/pages/-favorites.vue' /* webpackChunkName: "" */))
const _116e2f0a = () => interopDefault(import('~/pages/-customer-recruit-customers.vue' /* webpackChunkName: "" */))
const _05253540 = () => interopDefault(import('~/pages/-customer-recruit-customers-success.vue' /* webpackChunkName: "" */))
const _ab028c1e = () => interopDefault(import('~/pages/-contest.vue' /* webpackChunkName: "" */))
const _e5bc296e = () => interopDefault(import('~/pages/-newsletter-signup.vue' /* webpackChunkName: "" */))
const _6f29e93e = () => interopDefault(import('~/pages/-delete-account.vue' /* webpackChunkName: "" */))
const _6871497c = () => interopDefault(import('~/pages/-update-password.vue' /* webpackChunkName: "" */))
const _72861213 = () => interopDefault(import('~/pages/-access-code.vue' /* webpackChunkName: "" */))
const _2d2a8123 = () => interopDefault(import('~/pages/-error404.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/",
    component: _34fbf0e3,
    name: "index"
  }, {
    path: "/hotel/:hotelWebname/hotelgutschein/:productId-:webname",
    component: _f5fde97e,
    meta: {"isDetailsRoute":true},
    name: "hotelProductDetails"
  }, {
    path: "/buchung/:hotelWebname/hotelgutschein/:productId-:webname",
    component: _f5fde97e,
    meta: {"isDetailsRoute":true},
    name: "directBookingDetails"
  }, {
    path: "/hotel/:hotelWebname/hotelbon/:productId-:webname",
    component: _f5fde97e,
    meta: {"isDetailsRoute":true},
    name: "hotelProductDetails-nl"
  }, {
    path: "/reservering/:hotelWebname/hotelbon/:productId-:webname",
    component: _f5fde97e,
    meta: {"isDetailsRoute":true},
    name: "directBookingDetails-nl"
  }, {
    path: "/deal/:productId-:webname",
    component: _f5fde97e,
    meta: {"isDetailsRoute":true},
    name: "productDetails"
  }, {
    path: "/wertgutschein/:valueVoucherId-:webname",
    component: _62442680,
    meta: {"isDetailsRoute":true},
    name: "voucherDetails"
  }, {
    path: "/preview/:productId-:checksum",
    component: _26bf80e8,
    meta: {"isDetailsRoute":true},
    name: "productPreview"
  }, {
    path: "/hotel/:hotelId-:webname",
    component: _4e9be474,
    meta: {"isDetailsRoute":true},
    name: "hotelDetails"
  }, {
    path: "/checkout/view",
    component: _75d80d8e,
    meta: {"isCheckoutRoute":true},
    name: "checkout"
  }, {
    path: "/checkout/order/:orderId-:orderHash",
    component: _59fb6594,
    meta: {"isCheckoutRoute":true},
    name: "checkoutForOrder"
  }, {
    path: "/checkout/complete/:orderId-:orderHash",
    component: _6d8fb560,
    name: "success"
  }, {
    path: "/kontakt",
    component: _88cf7a06,
    name: "contact"
  }, {
    path: "/kontaktformular",
    component: _13f940e0,
    name: "contact-nettovorteile"
  }, {
    path: "/search",
    component: _33e005ff,
    name: "searchResult"
  }, {
    path: "/date-search",
    component: _18e55984,
    name: "dateSearch"
  }, {
    path: "/map",
    component: _998b0a28,
    name: "mapSearch"
  }, {
    path: "/c/:category",
    component: _f166661e,
    meta: {"isCategoryRoute":true},
    name: "category"
  }, {
    path: "/cms-preview/:category",
    component: _35988ca3,
    name: "categoryPreview"
  }, {
    path: "/tophotels/:start-:stop",
    component: _4cf5c56a,
    name: "topHotels"
  }, {
    path: "/widerruf",
    component: _611768f6,
    name: "cancellation"
  }, {
    path: "/cancellation",
    component: _611768f6,
    name: "cancellation-en"
  }, {
    path: "/herroeping",
    component: _611768f6,
    name: "cancellation-nl"
  }, {
    path: "/favoriten",
    component: _1d7e9874,
    name: "favorites"
  }, {
    path: "/favorites",
    component: _1d7e9874,
    name: "favorites-en"
  }, {
    path: "/favorieten",
    component: _1d7e9874,
    name: "favorites-nl"
  }, {
    path: "/kwk-share/",
    component: _116e2f0a,
    meta: {"isCheckoutRoute":true},
    name: "recruitCustomersInit"
  }, {
    path: "/kwk/",
    component: _05253540,
    meta: {"isCheckoutRoute":true},
    name: "recruitCustomersSuccess"
  }, {
    path: "/gewinnspiel-animod",
    component: _ab028c1e,
    meta: {"isCheckoutRoute":true},
    name: "contest"
  }, {
    path: "/newsletter-signup",
    component: _e5bc296e,
    meta: {"isNewsletterRoute":true},
    name: "newsletterSignup"
  }, {
    path: "/service/delete-account/:hash",
    component: _6f29e93e,
    name: "deleteAccount"
  }, {
    path: "/service/update-password/:token",
    component: _6871497c,
    name: "updatePassword"
  }, {
    path: "/access-code",
    component: _72861213,
    name: "accessCode"
  }, {
    path: "/not-found",
    component: _2d2a8123,
    name: "notFound"
  }, {
    path: "*",
    redirect: "/not-found"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
